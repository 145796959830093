<template>
  <div class="yunimage">
    <!-- 图片 -->
    <ul class="image w" v-if="isimg==1">
      <li>
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
      </li>
    </ul>

    <!-- 图片 -->
    <ul class="image1 w" v-if="isimg==2">
      <li>
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
        <img src="../assets/images/111.jpg" alt />
      </li>
    </ul>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
export default {
  name: "Yunimage",
  components: {
  },
  data() {
    return {
      isimg: "1"
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    }
  },
  mounted() {
    console.log(this.$route.query.id);
    // (this.isimg = this.$route.query.id || 1),
    //   (this.swiper = new Swiper(".swiper-container", {
    //     loop: true,
    //     // 如果需要前进后退按钮
    //     nextButton: ".swiper-button-next",
    //     prevButton: ".swiper-button-prev",
    //     autoplay: 5000,
    //     speed: 700,
    //     //用户操作之后是否停止自动轮播默认true
    //     autoplayDisableOnInteraction: false
    //   }));
  },
  methods: {
    // navleft() {
    //   this.swiper.slidePrev();
    // },
    // navright() {
    //   this.swiper.slideNext();
    // }
  }
};
</script>

<style scoped  lang="scss">
.banner {
  //   margin-bottom: 40px;
  margin-bottom: 80px;
  width: 100%;
  height: 500px;
  background: url("../assets/images/banner4.jpg") no-repeat;
  background-size: 100% 100%;

  .banner-btn {
    position: absolute;
    top: 315px;
    z-index: 100;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    background: rgba(51, 51, 51, 0);
    transition: all 0.1s;

    img {
      vertical-align: middle;
    }

    &:hover {
      background: rgba(51, 51, 51, 0.5);
    }

    &.banner-btn-left {
      left: 100px;
    }
    &.banner-btn-right {
      right: 100px;
    }
  }
}
.image1 {
  li {
    margin-bottom: 80px;
    img {
      margin-right: 20px;
      margin-bottom: 40px;
    }
    & {
      :nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.image {
  // background: chocolate;

  li {
    margin-bottom: 80px;
    img {
      margin-right: 20px;
      margin-bottom: 40px;
    }

    & {
      :nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}

.swiper-container {
  height: 500px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}
</style>