// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/banner4.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".banner[data-v-1034b41c]{margin-bottom:4.762vw;width:100%;height:29.762vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.banner .banner-btn[data-v-1034b41c]{position:absolute;top:18.75vw;z-index:100;width:4.167vw;height:4.167vw;text-align:center;line-height:4.167vw;background:rgba(51,51,51,0);transition:all .1s}.banner .banner-btn img[data-v-1034b41c]{vertical-align:middle}.banner .banner-btn[data-v-1034b41c]:hover{background:rgba(51,51,51,.5)}.banner .banner-btn.banner-btn-left[data-v-1034b41c]{left:5.952vw}.banner .banner-btn.banner-btn-right[data-v-1034b41c]{right:5.952vw}.image1 li[data-v-1034b41c]{margin-bottom:4.762vw}.image1 li img[data-v-1034b41c]{margin-right:1.19vw;margin-bottom:2.381vw}.image1 li[data-v-1034b41c] :nth-child(3n){margin-right:0}.image li[data-v-1034b41c]{margin-bottom:4.762vw}.image li img[data-v-1034b41c]{margin-right:1.19vw;margin-bottom:2.381vw}.image li[data-v-1034b41c] :nth-child(3n){margin-right:0}.swiper-container[data-v-1034b41c]{height:29.762vw;width:100%}.swiper-container .swiper-wrapper .swiper-slide[data-v-1034b41c]{text-align:center;line-height:29.762vw;height:100%;width:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;background-size:114.286vw 29.762vw}", ""]);
// Exports
module.exports = exports;
